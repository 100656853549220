<template>
    <div>
        <breadcrumbs :pages="[{ title: 'Home' }, { title: 'About', isActive: true  }]"></breadcrumbs>
        <b-row class="justify-content-md-center my-5">
            <b-col md="12">
                <h2 class="mb-4">About xSigma</h2>
            </b-col>
            <b-col md="4" class="bg-white pb-5 about-navs">
                <b-nav vertical class="c-nav-bar mt-4">
                    <b-nav-item @click="goTo('https://xsigma.com/')">Developers</b-nav-item>
                    <b-nav-item @click="goTo('http://xsigma.fi/xsigma.whitepaper.pdf')">Whitepaper</b-nav-item>
                    <b-nav-item @click="goTo('https://xsigma.gitbook.io/xsigma-guides/faqs')">FAQ</b-nav-item>
                    <b-nav-item @click="goTo('https://t.me/xsigma_global')">Telegram</b-nav-item>
                    <b-nav-item @click="goTo('https://discord.gg/FpkMHJq')">Discord</b-nav-item>
                    <b-nav-item @click="goTo('/risks')">Risks</b-nav-item>
                </b-nav>
            </b-col>
            <b-col md="8" class="pl-5 fs-14 font-weight-bold about-content">
                <h4>What is xSigma?</h4>
                <p>Stablecoin DEX backed by NASDAQ listed company</p>
                <hr>
                <p>xSigma is a decentralized stablecoin exchange backed by a public company (Nasdaq: ZKIN). It is an Ethereum-based dApp that facilitates trades between DAI/USDC/USDT stablecoins.</p>
                <b-jumbotron>
                    <p>xSigma rewards liquidity providers with a fraction of trading fees and SIG tokens. You need to stake LP tokens to receive SIG rewards.
                        <a href="https://docs.xsigma.fi/quickstart-guides/guides-for-lps">Guides for LPs</a> </p>
                </b-jumbotron>
                <p>
                    Anyone can use xSigma to swap between there three stablecoins using a crypto wallet. You need to use a web3-enabled wallet to trade and provide liquidity. More on Ethereum wallets:
                    <br>
                    <a href="https://docs.ethhub.io/using-ethereum/wallets/intro-to-ethereum-wallets/">https://docs.ethhub.io/using-ethereum/wallets/intro-to-ethereum-wallets/</a>
                </p>
                <p>
                    The SIG token is a basic unit in xSigma. SIG token holders are entitled to vote for  decisions involving the future of xSigma DEX. You can use your tokens to vote for  xSigma's future development via the DAO or to effect a buyback from the accumulated fees fund.
                    <a href="https://docs.xsigma.fi/sig-token">Read more</a>
                </p>
                <p>
                    The SIG LP token represents a pool share. It can be converted back anytime to underlying stablecoins.
                    <a href="https://docs.xsigma.fi/sig-lp-token">Read more.</a>
                </p>
                <p>
                    xSigma DEX is a product made by xSigma Lab (a subsidiary of ZK International). xSigma Lab owns 30% of the SIG token supply, but does not control xSigma DEX's operations directly. xSigma Lab doesn't have access to DEX liquidity or user funds.
                </p>
                <p>
                    xSigma DEX is based on a well-tested StableSwap algorithm, first introduced by Curve in a 2019 paper:
                    <br>
                    <a href="https://www.curve.fi/stableswap-paper.pdf">https://www.curve.fi/stableswap-paper.pdf</a>
                </p>
                <p>
                    Read xSigma DEX Whitepaper:
                    <br>
                    <a href="https://xsigma.fi/xsigma.whitepaper.pdf">https://xsigma.fi/xsigma.whitepaper.pdf</a>
                </p>
                <p>
                    Read more about xSigma Lab:
                    <br>
                    <a href="https://xsigma.com/">https://xsigma.com/</a>
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "About",
    methods: {
        goTo(url) {
            // window.location.href = url
            window.open(url, '_blank')
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";
h2 {
    color: $grey6;
}
.c-nav-bar {
    font-weight: 600;
    a {
        color: $black !important;
        &:hover {
            opacity: 0.7;
            text-decoration: underline;
        }
    }
}
.about-content {
    &>p {
        @media (max-width: $mobile-width) {
            margin: 0!important;
            padding: 0!important;
        }
    }
    @media (max-width: $mobile-width) {
        margin: 25px!important;
        padding: 0!important;
    }
    p {
        font-weight: 500;
    }
}
.about-navs {
    border-radius: 8px;
    @media (max-width: $mobile-width) {
        margin: 0;
    }
}
.fs-14 {
    font-size: 14px;
}
.connect-btn {
    outline: none !important;
    border: 0 !important;
    font-size: 14px;
    padding: 12px 40px;
    border-radius: 4px;
    color: white;
}
.jumbotron {
    background-color: #ffffff;
}
</style>
